import React, { useCallback, useMemo } from "react";
import { Button, DatePicker, Divider, Dropdown, Form, Input, Modal, Select, Switch, TimePicker } from "antd";
import { upperCaseStringFirstLetter } from "../../../utils/functions";
import { convertToLocalDate, disableDateBefore } from "../../../utils/functionsDatum";
import dayjs from "dayjs";
import { APIEnumTurnusTyp, APIEnumZeitTyp } from "../../../types/apiTypes";
import locale from "antd/es/date-picker/locale/de_DE";
import style from "../Datev/Datev.module.css";
import { useSollArbeitszeitStore } from "../../../store/zustandSollArbeitszeitenStore";
import { useGenericStore } from "../../../store/zustandGenericStore";

const IWAbwesenheitsmodal = React.memo((props: any) => {
  let [form] = Form.useForm(); //TODO CK

  const {
    setisUrlaubOderKrank,
    displayNewEvent,
    setDisplayDetailEditZeitKontext,
    isUrlaubOderKrank,
    setDisplayDetailBisEndlosAbwesenheit,
    displaySettingsModalAbwesenheit,
    displayDetailBisEndlosAbwesenheit,
    displayAbwesenheitSelectDisabled,
    displayAbwesenheitSelectSchule,
    displayDetailEditZeitKontext,
    displayFirstOpenAbwesenheit,
    setDisplayFirstOpenAbwesenheit,
    isFormChanged,
    setIsFormChanged,
  } = useSollArbeitszeitStore();

  const { detailData } = useGenericStore();

  const formattedDetailDatumVon = useMemo(
    () => dayjs(detailData.detailDatumVon).format(" DD.MM.YYYY"),
    [detailData.detailDatumVon]
  );
  const formattedDetailDatumBis = useMemo(
    () => dayjs(detailData.detailDatumBis).format(" DD.MM.YYYY"),
    [detailData.detailDatumBis]
  );

  const isDatumBisVisible = () => {
    if (displayDetailBisEndlosAbwesenheit) {
      return isUrlaubOderKrank;
    } else {
      return true;
    }
  };

  if (displayFirstOpenAbwesenheit) {
    form.resetFields();

    if (
      (detailData.detailAbwesenheitsTyp === APIEnumZeitTyp.KRANK ||
        detailData.detailAbwesenheitsTyp === APIEnumZeitTyp.URLAUB) &&
      isUrlaubOderKrank
    ) {
      setisUrlaubOderKrank(true);
    }
    if (
      (detailData.detailAbwesenheitsTyp !== APIEnumZeitTyp.KRANK ||
        detailData.detailAbwesenheitsTyp !== APIEnumZeitTyp.URLAUB) &&
      !isUrlaubOderKrank
    ) {
      setisUrlaubOderKrank(false);
    }

    setDisplayFirstOpenAbwesenheit(false);
  }

  const handleKontext = useCallback(
    (value: boolean) => {
      setDisplayDetailEditZeitKontext(value);
    },
    [setDisplayDetailEditZeitKontext]
  );

  const handleOkAbwesenheit = useCallback(
    (values) => {
      props.handleOkAbwesenheit(values, false);
    },
    [props.handleOkAbwesenheit]
  );

  // ✅ Erzeuge dynamisch in den Inhalt des Turnus-Select abhängig von Endlos und den Tagen zwischen von-bis
  const turnusOptionsWithRules = () => {
    let returnArray = [];

    let inputFeldAb = document.getElementById("gueltigAb") as HTMLInputElement;
    let inputFeldBis = document.getElementById("gueltigBis") as HTMLInputElement;
    let inputDauerBegrenzt = document.getElementById("dauerBegrenzt") as HTMLInputElement;

    returnArray.push({ value: APIEnumTurnusTyp.TAEGLICH, label: "Täglich" });

    if (displayDetailBisEndlosAbwesenheit) {
      returnArray.push({ value: APIEnumTurnusTyp.JEDEWOCHE, label: "Jede Woche" });
      returnArray.push({ value: APIEnumTurnusTyp.ZWEIWOECHENTLICH, label: "Jede zweite Woche" });
      returnArray.push({ value: APIEnumTurnusTyp.MONATLICH, label: "Jeden Monat" });
      returnArray.push({ value: APIEnumTurnusTyp.JAEHRLICH, label: "Jedes Jahr" });
    } else if (inputFeldAb != null && inputFeldBis != null) {
      let aktDatumAbString = inputFeldAb.value;
      let aktDatumBisString = inputFeldBis.value;
      let aktDatumAb = dayjs(aktDatumAbString, "DD.MM.YYYY");
      let aktDatumBis = dayjs(aktDatumBisString, "DD.MM.YYYY");
      let diffDays = aktDatumBis.diff(aktDatumAb, "day");

      if (diffDays > 14) returnArray.push({ value: APIEnumTurnusTyp.JEDEWOCHE, label: "Jede Woche" });
      if (diffDays > 28) returnArray.push({ value: APIEnumTurnusTyp.ZWEIWOECHENTLICH, label: "Jede zweite Woche" });
      if (diffDays > 60) returnArray.push({ value: APIEnumTurnusTyp.MONATLICH, label: "Jeden Monat" });
      if (diffDays > 720) returnArray.push({ value: APIEnumTurnusTyp.JAEHRLICH, label: "Jedes Jahr" });
    }

    return returnArray;
  };

  return (
    <Modal
      width={700}
      open={displaySettingsModalAbwesenheit}
      okText={"Speichern"}
      maskClosable={false}
      footer={false}
      closable={false}
      destroyOnClose={true}
    >
      <h3>
        {!displayDetailBisEndlosAbwesenheit ? (
          <b>
            Abwesenheit für {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" dddd"))},{" "}
            {convertToLocalDate(formattedDetailDatumVon)} - {convertToLocalDate(formattedDetailDatumBis)}
          </b>
        ) : (
          <b>
            Abwesenheit ab {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" dddd"))},{" "}
            {convertToLocalDate(formattedDetailDatumVon)}
          </b>
        )}
        <br />
        {detailData.detailMA}
      </h3>
      <Divider></Divider>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        style={{ maxWidth: 800 }}
        onFinish={handleOkAbwesenheit}
        autoComplete="off"
        onFieldsChange={function () {
          setIsFormChanged(true);
        }}
      >
        <Form.Item
          label="Typ :"
          name="detailAbwesenheitsTyp"
          style={{ marginBottom: "15px" }}
          initialValue={detailData.detailAbwesenheitsTyp}
        >
          <Select
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            options={[
              {
                value: APIEnumZeitTyp.URLAUB,
                label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.URLAUB),
              },
              { value: APIEnumZeitTyp.KRANK, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.KRANK) },
              /**{ value: APIEnumZeitTyp.FEIERTAG, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.FEIERTAG) },**/
              { value: APIEnumZeitTyp.SCHULE, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.SCHULE) },
              /**{ value: APIEnumZeitTyp.RUHETAG, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.RUHETAG) }, **/
              /**{
                     value: APIEnumZeitTyp.WOCHENENDE,
                     label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.WOCHENENDE),
                     },**/
            ]}
            disabled={displayAbwesenheitSelectDisabled}
            onChange={function (value) {
              props.handleChangeAbwesenheitTyp(value);
              if (value === APIEnumZeitTyp.KRANK || value === APIEnumZeitTyp.URLAUB) setisUrlaubOderKrank(true);
              else setisUrlaubOderKrank(false);
            }}
          />
        </Form.Item>

        {!isUrlaubOderKrank ? (
          <Form.Item label="Dauer" style={{ marginBottom: "0px" }}>
            <Form.Item
              name="unbegrenzt"
              style={{ display: "inline-block" }}
              initialValue={displayDetailBisEndlosAbwesenheit}
            >
              <Switch
                style={{
                  caretColor: "blue",
                  backgroundColor: displayDetailBisEndlosAbwesenheit ? "CornFlowerBlue" : "lightgray",
                }}
                defaultChecked={displayDetailBisEndlosAbwesenheit}
                checkedChildren="Unbegrenzte Dauer"
                unCheckedChildren="Begrenzte Dauer"
                onChange={function (checked: boolean) {
                  turnusOptionsWithRules();
                  setDisplayDetailBisEndlosAbwesenheit(checked);
                }}
              ></Switch>
            </Form.Item>
          </Form.Item>
        ) : null}

        <Form.Item label="Gültig ab:" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="gueltigAb"
            style={{ display: "inline-block" }}
            initialValue={dayjs(detailData.detailDatumVon)}
          >
            <DatePicker
              picker={"date"}
              id={"gueltigAb"}
              value={dayjs(detailData.detailDatumVon)}
              allowClear={false}
              format={"DD.MM.YYYY"}
              locale={locale}
              disabledDate={disableDateBefore}
              onChange={function () {
                turnusOptionsWithRules();
              }}
              inputReadOnly
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              className={style.iwMonthPicker}
            />
          </Form.Item>
          {isDatumBisVisible() ? (
            <>
              <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                <label>bis</label>
              </Form.Item>
              <Form.Item
                label=""
                name="gueltigBis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs(detailData.detailDatumBis)}
              >
                <DatePicker
                  picker={"date"}
                  id={"gueltigBis"}
                  allowClear={false}
                  format={"DD.MM.YYYY"}
                  locale={locale}
                  inputReadOnly
                  disabledDate={disableDateBefore}
                  onChange={function () {
                    turnusOptionsWithRules();
                  }}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </>
          ) : null}
        </Form.Item>

        {displayAbwesenheitSelectSchule ? (
          <Form.Item
            shouldUpdate={(prev, curr) => prev.displayAbwesenheitSelectSchule !== curr.displayAbwesenheitSelectSchule}
          >
            {() => (
              <Form.Item label="Uhrzeit von :" style={{ marginBottom: "0px" }}>
                <Form.Item
                  label=""
                  name="ArbeitszeitAb"
                  style={{ display: "inline-block" }}
                  initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
                >
                  <TimePicker
                    defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
                    allowClear={false}
                    format={"HH:mm"}
                    locale={locale}
                    showNow={false}
                    minuteStep={5}
                    changeOnBlur={true}
                    style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  />
                </Form.Item>
                <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                  <label>bis</label>
                </Form.Item>
                <Form.Item
                  label=""
                  name="ArbeitszeitBis"
                  style={{ display: "inline-block", margin: "0 8px" }}
                  initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
                >
                  <TimePicker
                    defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
                    allowClear={false}
                    format={"HH:mm"}
                    locale={locale}
                    showNow={false}
                    minuteStep={5}
                    changeOnBlur={true}
                    style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                    className={style.iwMonthPicker}
                  />
                </Form.Item>
              </Form.Item>
            )}
          </Form.Item>
        ) : null}

        <Form.Item
          label="Intervall:"
          name="turnus"
          style={{ marginBottom: "15px" }}
          initialValue={detailData.detailTurnusTyp}
        >
          <Select
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            options={turnusOptionsWithRules()}
            disabled={isUrlaubOderKrank}
          />
        </Form.Item>

        <Form.Item
          label="Bemerkung:"
          name="bemerkung"
          style={{ marginBottom: "25px" }}
          initialValue={detailData.detailBemerkung}
        >
          <Input
            defaultValue={"" + detailData.detailBemerkung}
            style={{ backgroundColor: "#e1f0fc", width: "565px" }}
          ></Input>
        </Form.Item>
        <Divider></Divider>
        {false ? (
          <Form.Item label="Gültigkeit:" style={{ marginBottom: "0px" }}>
            <Form.Item
              label=""
              name="tagOderTurnus"
              style={{ display: "inline-block" }}
              initialValue={displayDetailEditZeitKontext}
            >
              <Switch
                style={{
                  caretColor: "black",
                  color: "black",
                  width: "150px",
                  backgroundColor: "CornFlowerBlue",
                }}
                defaultChecked={displayDetailEditZeitKontext}
                checkedChildren="Ganze Serie"
                unCheckedChildren="Nur dieser Tag"
                onChange={function (checked: boolean) {
                  handleKontext(checked);
                }}
              ></Switch>
            </Form.Item>
          </Form.Item>
        ) : null}
        <Form.Item wrapperCol={{ offset: 0, span: 30 }} style={{ marginBottom: "0px" }}>
          {!displayNewEvent ? (
            <>
              <Button
                type="primary"
                onClick={props.handleCancelEinstellungen}
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "#4096ff",
                  marginLeft: "450px",
                  marginRight: "5px",
                }}
              >
                Abbrechen
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              onClick={props.handleCancelEinstellungen}
              style={{
                backgroundColor: "#f5f5f5",
                color: "#4096ff",
                marginLeft: "453px",
                marginRight: "5px",
              }}
            >
              Abbrechen
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default IWAbwesenheitsmodal;
