import React, { useCallback, useMemo } from "react";
import { Button, Divider, Form, Modal, Switch } from "antd";
import { convertToLocalDate } from "../../../utils/functionsDatum";
import dayjs from "dayjs";
import { useSollArbeitszeitStore } from "../../../store/zustandSollArbeitszeitenStore";
import { useGenericStore } from "../../../store/zustandGenericStore";

const IWLoeschenmodal = React.memo((props: any) => {
  let [form] = Form.useForm(); //TODO CK

  const { displayDetailBisEndlosAbwesenheit, displayModalLoeschen, setDisplayDetailEditZeitKontext } =
    useSollArbeitszeitStore();

  const { detailData } = useGenericStore();

  const formattedDetailDatumVon = useMemo(
    () => dayjs(detailData.detailDatumVon).format(" DD.MM.YYYY"),
    [detailData.detailDatumVon]
  );
  const formattedDetailDatumBis = useMemo(
    () => dayjs(detailData.detailDatumBis).format(" DD.MM.YYYY"),
    [detailData.detailDatumBis]
  );

  const getTypFromDetailData = () => {
    let stringReturn = detailData.detailAbwesenheitsTyp;
    if (stringReturn != null && stringReturn.includes("Stunden")) stringReturn = "Arbeitszeit";
    return stringReturn;
  };

  const handleOkAbwesenheit = useCallback(
    (values) => {
      props.handleOkAbwesenheit(values, false);
    },
    [props.handleOkAbwesenheit]
  );

  const isEndlos = () => {
    if (displayDetailBisEndlosAbwesenheit) return "Begrenzt Gültig";
    else return "Unbegrenzt Gültig";
  };

  const generateDatumBis = () => {
    if (convertToLocalDate(formattedDetailDatumBis).includes("2099"))
      return (
        convertToLocalDate(dayjs(detailData.detailDatumVon).format(" dddd")) +
        ", ab " +
        convertToLocalDate(formattedDetailDatumVon) +
        " " +
        "löschen"
      );
    else
      return (
        convertToLocalDate(dayjs(detailData.detailDatumVon).format(" dddd")) +
        " von " +
        convertToLocalDate(formattedDetailDatumVon) +
        " - " +
        convertToLocalDate(formattedDetailDatumBis) +
        " löschen"
      );
  };

  const generateDatumAnzeige = () => {
    if (convertToLocalDate(formattedDetailDatumBis).includes("2099"))
      return " ab " + convertToLocalDate(formattedDetailDatumVon);
    else return convertToLocalDate(formattedDetailDatumVon) + " - " + convertToLocalDate(formattedDetailDatumBis);
  };

  return (
    <Modal
      width={500}
      open={displayModalLoeschen}
      okText={"Speichern"}
      maskClosable={false}
      footer={false}
      closable={false}
      destroyOnClose={true}
    >
      <h3>
        <b>{generateDatumBis()}</b>

        <br />
        {detailData.detailMA}
      </h3>
      <Divider></Divider>
      <b>Der folgende Eintrag soll gelöscht werden:</b>
      <br />
      <br />
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 21 }}
        style={{ maxWidth: 500 }}
        onFinish={handleOkAbwesenheit}
        autoComplete="off"
      >
        <Form.Item label="Typ :" name="detailAbwesenheitsTyp" style={{ marginBottom: "0px" }}>
          {getTypFromDetailData()}
        </Form.Item>

        <Form.Item label="Dauer" style={{ marginBottom: "0px" }}>
          {isEndlos()}
        </Form.Item>

        <Form.Item label="Gültig ab:" style={{ marginBottom: "0px" }}>
          <Form.Item label="" name="gueltigAb" style={{ display: "inline-block", marginBottom: "0px" }}>
            {generateDatumAnzeige()}
          </Form.Item>
        </Form.Item>

        <Form.Item label="Uhrzeit von :" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="ArbeitszeitAb"
            style={{ display: "inline-block", marginBottom: "0px" }}
            initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
          >
            {detailData.detailUhrzeitVon}
          </Form.Item>
          <Form.Item style={{ display: "inline-block", marginBottom: "0px" }}>
            <label>&nbsp;bis {detailData.detailUhrzeitBis}</label>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Intervall:"
          name="turnus"
          style={{ marginBottom: "0px" }}
          initialValue={detailData.detailTurnusTyp}
        >
          {detailData.detailTurnusTyp}
        </Form.Item>

        <Form.Item
          label="Bemerkung:"
          name="bemerkung"
          style={{ marginBottom: "0px" }}
          initialValue={detailData.detailBemerkung}
        >
          {detailData.detailBemerkung}
        </Form.Item>

        <Form.Item style={{ marginBottom: "0px", marginTop: "0px" }}>
          Möchtest du die ganze Serie löschen oder nur diesen Eintrag?
        </Form.Item>
        <Form.Item
          label=""
          name="typ"
          style={{ marginBottom: "0px", marginTop: "0px" }}
          initialValue={detailData.detailBemerkung}
        >
          <Switch
            style={{
              caretColor: "black",
              color: "black",
              width: "150px",
              backgroundColor: "CornFlowerBlue",
            }}
            defaultChecked={true}
            checkedChildren="Ganze Serie"
            unCheckedChildren="Nur dieser Tag"
            onChange={function (checked: boolean) {
              setDisplayDetailEditZeitKontext(checked);
            }}
          ></Switch>
        </Form.Item>
        <Divider></Divider>

        <Form.Item wrapperCol={{ offset: 0, span: 30 }} style={{ marginBottom: "0px" }}>
          <Button
            type="primary"
            onClick={props.handleCancelLoeschen}
            style={{
              backgroundColor: "#f5f5f5",
              color: "#4096ff",
              marginLeft: "253px",
              marginRight: "5px",
            }}
          >
            Abbrechen
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleOkLoeschen}
            style={{
              backgroundColor: "#dd4444",
              color: "#f5f5f5",
            }}
          >
            Löschen
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default IWLoeschenmodal;
