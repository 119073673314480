import create from "zustand";

const loggedInLS: boolean = !!(localStorage.loginInfo && JSON.parse(localStorage.loginInfo).benutzername);

export interface AppStateInterface {
  isOffline: boolean;
  setIsOffline: (isOffline: boolean) => void;
  isServerDown: boolean;
  setIsServerDown: (isServerDown: boolean) => void;
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
  loggedInForModule: boolean;
  setLoggedInForModule: (loggedInForModule: boolean) => void;
  isToggled: boolean;
  setToggled: (isToggled: boolean) => void;
  isNavLeftVisible: boolean;
  setIsNavLeftVisible: (isNavLeftVisible: boolean) => void;
  isConnectLoading: boolean;
  setIsConnectLoading: (isConnectLoading: boolean) => void;
  connectLoadingStatus: number;
  setConnectLoadingStatus: (connectLoadingStatus: number) => void;
  isNoModulLoginDone: boolean;
  setIsNoModulLoginDone: (isNoModulLoginDone: boolean) => void;
  isModulLoginDone: boolean;
  setIsModulLoginDone: (isModulLoginDone: boolean) => void;
  runningLoading: boolean;
  setRunningLoading: (runningLoading: boolean) => void;
  navLeftSize: number;
  setNavLeftSize: (navLeftSize: number) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  benutzername: string;
  setBenutzername: (benutzername: string) => void;
  passwort: string;
  setPasswort: (passwort: string) => void;
  openKeys: string[];
  setOpenKeys: (openKeys: string[]) => void;
  selectedKeys: string[];
  setSelectedKeys: (selectedKeys: string[]) => void;
  pName: string;
  setPName: (pName: string) => void;
}

export const useAppStore = create<AppStateInterface>()((set) => ({
  isModalOpen: false,
  navLeftSize: 375,
  setIsModalOpen: (isModalOpen: boolean) => set({ isModalOpen }),
  setNavLeftSize: (navLeftSize: number) => set({ navLeftSize }),
  connectLoadingStatus: 0,
  isConnectLoading: true,
  isModulLoginDone: false,
  isNavLeftVisible: true,
  isNoModulLoginDone: false,
  isOffline: false,
  isServerDown: false,
  isToggled: false,
  loggedIn: loggedInLS,
  loggedInForModule: false,
  runningLoading: false,
  openKeys: [],
  setOpenKeys: (openKeys: string[]) => set({ openKeys }),
  selectedKeys: [],
  setSelectedKeys: (selectedKeys: string[]) => set({ selectedKeys }),
  pName: "",
  setPName: (pName: string) => set({ pName }),
  setConnectLoadingStatus: (connectLoadingStatus: number) => set({ connectLoadingStatus }),
  setIsConnectLoading: (isConnectLoading: boolean) => set({ isConnectLoading }),
  setIsModulLoginDone: (isModulLoginDone: boolean) => set({ isModulLoginDone }),
  setIsNavLeftVisible: (isNavLeftVisible: boolean) => set({ isNavLeftVisible }),
  setIsNoModulLoginDone: (isNoModulLoginDone: boolean) => set({ isNoModulLoginDone }),
  setIsOffline: (isOffline: boolean) => set({ isOffline }),
  setIsServerDown: (isServerDown: boolean) => set({ isServerDown }),
  setLoggedIn: (loggedIn: boolean) => set({ loggedIn }),
  setLoggedInForModule: (loggedInForModule: boolean) => set({ loggedInForModule }),
  setRunningLoading: (runningLoading: boolean) => set({ runningLoading }),
  setToggled: (isToggled: boolean) => set({ isToggled }),
  benutzername: "",
  passwort: "",
  setBenutzername: (benutzername: string) => set({ benutzername }),
  setPasswort: (passwort: string) => set({ passwort }),
}));
