import React, { Key, useState } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Layout, Menu, MenuProps } from "antd";
import { EuroCircleOutlined, MenuOutlined } from "@ant-design/icons";
import style from "./NavLeft.module.css";
import { useFilialen, useFilialenPlaner } from "../../../hooks/useFilialen";
import { useVerbuende } from "../../../hooks/useVerbuende";
import { APIFiliale } from "../../../types/apiTypes";
import { isCompanyAdministrator, isSalonManagerAndAbove } from "../../../utils/functionsSecurity";
import { useStammdaten } from "../../../hooks/useStammdaten";
import { cmdParameter } from "../../App/App";
import { GENERICTYPE_PARAMETER_ACTIVEMODUL } from "../../../types/genericTypes";
import { useAppStore } from "../../../store/zustandAppStore";
import { useParams } from "react-router";
import { useArbeitszeitStore } from "../../../store/zustandArbeitszeitenStore";

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavLeft = () => {
  const { pathname } = useLocation();

  const {
    setIsNavLeftVisible,
    navLeftSize,
    setNavLeftSize,
    openKeys,
    setOpenKeys,
    selectedKeys,
    setSelectedKeys,
    pName,
    setPName,
  } = useAppStore();

  if (pathname.split("/")[1] !== pName) {
    setPName(pathname.split("/")[1]);
    setOpenKeys([]);
    setSelectedKeys([]);
  }

  // Event-Handler für das Ändern der offenen Menüs
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    setOpenKeys(keys); // Setzt die aktuell geöffneten Menü-Keys
  };
  // Event-Handler für Menü-Klicks
  const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    setSelectedKeys([key]); // Setzt den ausgewählten Menü-Key
  };

  const isDatevPage = useRouteMatch("/datev") !== null;
  const isStatistikPage = useRouteMatch("/statistik") !== null;
  const isArbeitszeitenPage = useRouteMatch("/arbeitszeiten") !== null;
  const isSollArbeitszeitenPage = useRouteMatch("/sollarbeitszeiten") !== null;
  const isPlaner = useRouteMatch("/planer") !== null;

  const { stammdaten } = useStammdaten();
  const syncMitarbeiter = stammdaten.syncTabellen ? stammdaten.syncTabellen.includes("ma_info") : false;
  const syncKonten = stammdaten.syncTabellen ? stammdaten.syncTabellen.includes("bh_konten") : false;

  if (
    !cmdParameter.activeModules.includes(GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT) &&
    navLeftSize != 0
  ) {
    setNavLeftSize(0);

    setIsNavLeftVisible(false);
  }

  let statistikIDA: number = 100;
  if (pathname.split("/")[2] !== undefined && pathname.split("/")[2] !== null && pathname.split("/")[2] !== "") {
    statistikIDA = parseInt(pathname.split("/")[2]);
  }

  const { filialen } = useFilialen({
    enabled: isDatevPage || isStatistikPage || isArbeitszeitenPage || isSollArbeitszeitenPage || isPlaner,
  });
  const { filialenPlaner } = useFilialenPlaner({
    enabled: isDatevPage || isStatistikPage || isArbeitszeitenPage || isSollArbeitszeitenPage || isPlaner,
  });

  const { verbuende } = useVerbuende({
    enabled: isDatevPage || isStatistikPage || isArbeitszeitenPage || isSollArbeitszeitenPage,
  });

  const renderFilialeDatev = (filiale: APIFiliale) => (
    <Menu.Item key={filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/datev/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeDatevVerbund = (filiale: APIFiliale) => (
    <Menu.Item key={"dv" + filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/datev/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeArbeitszeiten = (filiale: APIFiliale) => (
    <Menu.Item key={"f" + filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/arbeitszeiten/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeArbeitszeitenVerbund = (filiale: APIFiliale) => (
    <Menu.Item key={"v" + filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/arbeitszeiten/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeSollArbeitszeiten = (filiale: APIFiliale) => (
    <Menu.Item key={"sf" + filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/sollarbeitszeiten/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeSollArbeitszeitenVerbund = (filiale: APIFiliale) => (
    <Menu.Item key={"sv" + filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/sollarbeitszeiten/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeStatistik = (filiale: APIFiliale) => (
    <Menu.Item key={"s" + statistikIDA + filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/statistik/${statistikIDA}/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialePlaner = (filiale: APIFiliale) => (
    <Menu.Item key={filiale.connectToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/planer/${encodeURIComponent(filiale.connectToken)}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeStatistikVerbund = (filiale: APIFiliale) => (
    <Menu.Item key={"sv" + statistikIDA + filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/statistik/${statistikIDA}/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const getBooleanForMenu = (isAlle: boolean, isVerbund: boolean, statistikID: number) => {
    for (let i = 0; i < stammdaten.statistiken.length; i++)
      if (stammdaten.statistiken[i].id === statistikID) {
        //console.log(stammdaten.statistiken[i].id);
        if (stammdaten.statistiken[i].showAlleFilialenMenu && isAlle) {
          return true;
        } else if (stammdaten.statistiken[i].showVerbuendeMenu && isVerbund) {
          return true;
        }
      }
    return false;
  };

  const handleMenuSwitch = () => {
    if (navLeftSize !== 0) {
      setNavLeftSize(0);
      setIsNavLeftVisible(false);
    } else {
      setNavLeftSize(375);
      setIsNavLeftVisible(true);
    }
  };

  return (
    <>
      {cmdParameter.activeModules.includes(
        GENERICTYPE_PARAMETER_ACTIVEMODUL.GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT
      ) ? (
        <>
          <MenuOutlined
            size={60}
            style={{ fontSize: "22px", color: "grey", position: "absolute", left: 15, top: 25 }}
            onClick={handleMenuSwitch}
          />
        </>
      ) : null}

      <Sider
        width={navLeftSize}
        className="site-layout-background"
        style={{
          textAlign: "center",
          fontSize: "x-large",
          color: "white",
          overflowY: "scroll",
        }}
      >
        <Menu
          mode="inline"
          theme="dark"
          style={{ width: "100%", height: "100%", borderRadius: "0px" }}
          openKeys={openKeys} // Anbindung an den Zustand `openKeys`
          selectedKeys={selectedKeys} // Anbindung an den Zustand `selectedKeys`
          onOpenChange={onOpenChange} // Steuerung für das Öffnen der Menüs
          onClick={handleMenuClick} // Bei Klick wird der ausgewählte Menüpunkt gesetzt
        >
          {pathname === "/home" && []}

          {isDatevPage ? (
            <>
              {syncKonten ? (
                <>
                  <Menu.Item key="sub0" style={{ borderRadius: "0px", textAlign: "left" }}>
                    <Link to="/datev/all">Alle Filialen</Link>
                  </Menu.Item>
                  <SubMenu key="sub1" title="Verbund" style={{ borderRadius: "0px", textAlign: "left" }}>
                    {verbuende && filialen
                      ? verbuende.map((verbund) => (
                          <SubMenu
                            key={verbund.verbundToken}
                            title={verbund.alias}
                            className={style.MenuEintrag}
                            style={{ borderRadius: "0px", textAlign: "left" }}
                          >
                            <Menu.Item
                              key={"v" + verbund.verbundToken}
                              style={{ borderRadius: "0px", textAlign: "left" }}
                            >
                              <Link to={`/datev/verbund/${verbund.verbundToken}`}>{verbund.alias} GESAMT</Link>
                            </Menu.Item>
                            {verbund.filialen.map((filialKey) => {
                              const filiale = filialen.find((f) => f.filialToken === filialKey);
                              return filiale ? renderFilialeDatevVerbund(filiale) : null;
                            })}
                          </SubMenu>
                        ))
                      : null}
                  </SubMenu>
                </>
              ) : null}
              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialen ? filialen.map(renderFilialeDatev) : null}
              </SubMenu>
            </>
          ) : null}

          {isStatistikPage ? (
            <>
              {isCompanyAdministrator() && getBooleanForMenu(true, false, statistikIDA) ? (
                <Menu.Item key="sub0" style={{ borderRadius: "0px", textAlign: "left" }}>
                  <Link to={`/statistik/${statistikIDA}/all`}>Alle Filialen</Link>
                </Menu.Item>
              ) : null}
              {isCompanyAdministrator() && getBooleanForMenu(false, true, statistikIDA) ? (
                <SubMenu key="sub1" title="Verbund" style={{ borderRadius: "0px", textAlign: "left" }}>
                  {verbuende && filialen
                    ? verbuende.map((verbund) => (
                        <SubMenu
                          key={verbund.verbundToken}
                          title={verbund.alias}
                          className={style.MenuEintrag}
                          style={{ borderRadius: "0px", textAlign: "left" }}
                        >
                          <Menu.Item
                            key={"sv" + verbund.verbundToken}
                            style={{ borderRadius: "0px", textAlign: "left" }}
                          >
                            <Link to={`/statistik/${statistikIDA}/verbund/${verbund.verbundToken}`}>
                              {verbund.alias} GESAMT
                            </Link>
                          </Menu.Item>
                          {verbund.filialen.map((filialKey) => {
                            const filiale = filialen.find((f) => f.filialToken === filialKey);
                            return filiale ? renderFilialeStatistikVerbund(filiale) : null;
                          })}
                        </SubMenu>
                      ))
                    : null}
                </SubMenu>
              ) : null}
              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialen ? filialen.map(renderFilialeStatistik) : null}
              </SubMenu>
            </>
          ) : null}

          {isArbeitszeitenPage ? (
            <>
              {syncMitarbeiter && isCompanyAdministrator() ? (
                <Menu.Item key="sub0" style={{ borderRadius: "0px", textAlign: "left" }}>
                  <Link to={`/arbeitszeiten/all`}>Alle Filialen</Link>
                </Menu.Item>
              ) : null}
              {syncMitarbeiter && isSalonManagerAndAbove() ? (
                <SubMenu key="sub1" title="Verbund" style={{ borderRadius: "0px", textAlign: "left" }}>
                  {verbuende && filialen
                    ? verbuende.map((verbund) => (
                        <SubMenu key={verbund.verbundToken} title={verbund.alias} className={style.MenuEintrag}>
                          <Menu.Item
                            key={"v" + verbund.verbundToken}
                            style={{ borderRadius: "0px", textAlign: "left" }}
                          >
                            <Link to={`/arbeitszeiten/verbund/${verbund.verbundToken}`}>{verbund.alias} GESAMT</Link>
                          </Menu.Item>
                          {verbund.filialen.map((filialKey) => {
                            const filiale = filialen.find((f) => f.filialToken === filialKey);
                            return filiale ? renderFilialeArbeitszeitenVerbund(filiale) : null;
                          })}
                        </SubMenu>
                      ))
                    : null}
                </SubMenu>
              ) : null}

              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialen ? filialen.map(renderFilialeArbeitszeiten) : null}
              </SubMenu>
            </>
          ) : null}

          {isSollArbeitszeitenPage ? (
            <>
              {syncMitarbeiter && isCompanyAdministrator() ? (
                <Menu.Item key="sub0" style={{ borderRadius: "0px", textAlign: "left" }}>
                  <Link to={`/sollarbeitszeiten/all`}>Alle Filialen</Link>
                </Menu.Item>
              ) : null}
              {syncMitarbeiter && isSalonManagerAndAbove() ? (
                <SubMenu key="sub1" title="Verbund" style={{ borderRadius: "0px", textAlign: "left" }}>
                  {verbuende && filialen
                    ? verbuende.map((verbund) => (
                        <SubMenu key={verbund.verbundToken} title={verbund.alias} className={style.MenuEintrag}>
                          <Menu.Item
                            key={"v" + verbund.verbundToken}
                            style={{ borderRadius: "0px", textAlign: "left" }}
                          >
                            <Link to={`/sollarbeitszeiten/verbund/${verbund.verbundToken}`}>
                              {verbund.alias} GESAMT
                            </Link>
                          </Menu.Item>
                          {verbund.filialen.map((filialKey) => {
                            const filiale = filialen.find((f) => f.filialToken === filialKey);
                            return filiale ? renderFilialeSollArbeitszeitenVerbund(filiale) : null;
                          })}
                        </SubMenu>
                      ))
                    : null}
                </SubMenu>
              ) : null}

              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialen ? filialen.map(renderFilialeSollArbeitszeiten) : null}
              </SubMenu>
            </>
          ) : null}

          {isPlaner ? (
            <>
              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialenPlaner ? filialenPlaner.map(renderFilialePlaner) : null}
              </SubMenu>
            </>
          ) : null}

          {pathname === "/filial_manager" && (
            <Menu.Item key="1" icon={<EuroCircleOutlined />}>
              <Link to="/filial_manager">Dummy</Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
    </>
  );
};

export default NavLeft;
