import create from "zustand";
import { DEFAULT_DATEPICKER_END_MOMENT, DEFAULT_DATEPICKER_START_MOMENT } from "../constants";
import { typeModalBestaetigung, typeModalBestaetigungCopy } from "../types/sollArbeitszeitenTypes";

const defaultStartDate: string =
  DEFAULT_DATEPICKER_START_MOMENT.startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z";
const defaultEndDate: string = DEFAULT_DATEPICKER_END_MOMENT.endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z";

export interface SollArbeitszeitStateInterface {
  aktCalendarView: string;
  aktFilialToken: string;
  dateData: { startDateData: string; endDateData: string };

  displayAbwesenheitSelect: string;
  displayAbwesenheitSelectDisabled: boolean;
  displayAbwesenheitSelectMAFA: boolean;
  displayAbwesenheitSelectSchule: boolean;
  displayDetailBisEndlosAbwesenheit: boolean;
  displayDetailBisEndlosArbeitszeit: boolean;
  displayDetailEditZeitKontext: boolean;
  displayDetailPause: boolean;
  displayModalBestaetigung: boolean;
  displayModalBestaetigungContent: typeModalBestaetigung;
  displayModalBestaetigungIsDelete: boolean;
  displayModalBestaetigungKopieren: boolean;
  displayModalBestaetigungKopierenText: string;
  displayModalBestaetigungValues: {};
  displayModalCopyContent: typeModalBestaetigungCopy;
  displayModalError: boolean;
  displayModalLoeschen: boolean;
  displayModalErrorContent: typeModalBestaetigung;
  displayFirstOpenArbeitszeit: boolean;
  displayFirstOpenAbwesenheit: boolean;
  displayModalSelectMAFA: boolean;
  displayModalUrlaubsanspruch: boolean;
  displayModalDienstplaeneBeenden: boolean;
  displayNewEvent: boolean;
  displaySettingsModalAbwesenheit: boolean;
  displaySettingsModalArbeitszeit: boolean;
  displayUrlaubsanspruch: number;
  endDate: string;
  isBackDelete: boolean;
  isUrlaubOderKrank: boolean;
  neuerMAFA: number;
  okButtonColor: string;
  setAktCalendarView: (aktCalendarView: string) => void;
  setAktFilialToken: (aktFilialToken: string) => void;
  setDateData: (dateData: { startDateData: string; endDateData: string }) => void;
  setDisplayFirstOpenArbeitszeit: (displayFirstOpenArbeitszeit: boolean) => void;
  setDisplayFirstOpenAbwesenheit: (displayFirstOpenAbwesenheit: boolean) => void;
  setDisplayAbwesenheitSelect: (displayAbwesenheitSelect: string) => void;
  setDisplayAbwesenheitSelectDisabled: (displayAbwesenheitSelectDisabled: boolean) => void;
  setDisplayAbwesenheitSelectMAFA: (displayAbwesenheitSelectMAFA: boolean) => void;
  setDisplayAbwesenheitSelectSchule: (displayAbwesenheitSelectSchule: boolean) => void;
  setDisplayDetailBisEndlosAbwesenheit: (displayDetailBisEndlosAbwesenheit: boolean) => void;
  setDisplayDetailBisEndlosArbeitszeit: (displayDetailBisEndlosArbeitszeit: boolean) => void;
  setDisplayDetailEditZeitKontext: (displayDetailEditZeitKontext: boolean) => void;
  setDisplayDetailPause: (displayDetailPause: boolean) => void;
  setDisplayModalBestaetigung: (isUrlaubOderKrank: boolean) => void;
  setDisplayModalBestaetigungContent: (displayModalBestaetigungContent: typeModalBestaetigung) => void;
  setDisplayModalBestaetigungIsDelete: (displayModalBestaetigungIsDelete: boolean) => void;
  setDisplayModalBestaetigungKopieren: (displayModalBestaetigungKopieren: boolean) => void;
  setDisplayModalBestaetigungKopierenText: (displayModalBestaetigungKopierenText: string) => void;
  setDisplayModalBestaetigungValues: (isUrlaubOderKrank: {}) => void;
  setDisplayModalCopyContent: (displayModalCopyContent: typeModalBestaetigungCopy) => void;
  setDisplayModalError: (displayModalError: boolean) => void;
  setDisplayModalLoeschen: (displayModalLoeschen: boolean) => void;
  setDisplayModalErrorContent: (displayModalErrorContent: typeModalBestaetigung) => void;

  setDisplayModalSelectMAFA: (displayModalSelectMAFA: boolean) => void;
  setDisplayModalUrlaubsanspruch: (displayModalUrlaubsanspruch: boolean) => void;
  setDisplayModalDienstplaeneBeenden: (displayModalDienstplaeneBeenden: boolean) => void;
  setDisplayNewEvent: (displayNewEvent: boolean) => void;
  setDisplaySettingsModalAbwesenheit: (displaySettingsModalAbwesenheit: boolean) => void;
  setDisplaySettingsModalArbeitszeit: (displaySettingsModalArbeitszeit: boolean) => void;
  setDisplayUrlaubsanspruch: (displayUrlaubsanspruch: number) => void;
  setEndDate: (endDate: string) => void;
  setIsBackDelete: (isBackDelete: boolean) => void;
  setNeuerMAFA: (neuerMAFA: number) => void;
  setOKButtonColor: (okButtonColor: string) => void;
  setStartDate: (startDate: string) => void;
  setisUrlaubOderKrank: (isUrlaubOderKrank: boolean) => void;
  startDate: string;
  isFormChanged: boolean;
  setIsFormChanged: (isFormChanged: boolean) => void;
}

export const useSollArbeitszeitStore = create<SollArbeitszeitStateInterface>()((set) => ({
  aktCalendarView: "Monatsansicht",
  aktFilialToken: "",
  dateData: {
    startDateData: defaultStartDate,
    endDateData: defaultEndDate,
  },
  displayFirstOpenArbeitszeit: true,
  displayFirstOpenAbwesenheit: true,
  displayAbwesenheitSelect: "",
  displayAbwesenheitSelectDisabled: true,
  displayAbwesenheitSelectMAFA: false,
  displayAbwesenheitSelectSchule: false,
  displayDetailBisEndlosAbwesenheit: false,
  displayDetailBisEndlosArbeitszeit: false,
  displayDetailEditZeitKontext: false,
  displayDetailPause: false,
  displayModalBestaetigung: false,
  displayModalBestaetigungContent: {} as typeModalBestaetigung,
  displayModalBestaetigungIsDelete: false,
  displayModalBestaetigungKopieren: false,
  displayModalBestaetigungKopierenText: "",
  displayModalBestaetigungValues: {},
  displayModalCopyContent: {} as typeModalBestaetigungCopy,
  displayModalError: false,
  displayModalLoeschen: false,
  displayModalErrorContent: {} as typeModalBestaetigung,

  displayModalSelectMAFA: false,
  displayModalUrlaubsanspruch: false,
  displayModalDienstplaeneBeenden: false,
  displayNewEvent: false,
  displaySettingsModalAbwesenheit: false,
  displaySettingsModalArbeitszeit: false,
  displayUrlaubsanspruch: 0,
  endDate: DEFAULT_DATEPICKER_END_MOMENT.format("YYYY-MM-DD") + "T00:00:00.000Z",
  isBackDelete: false,
  isUrlaubOderKrank: false,
  neuerMAFA: 0,
  okButtonColor: "#4096ff",
  setAktCalendarView: (aktCalendarView: string) => set({ aktCalendarView }),
  setAktFilialToken: (aktFilialToken: string) => set({ aktFilialToken }),
  setDateData: (dateData: { startDateData: string; endDateData: string }) => set({ dateData }),
  setDisplayFirstOpenArbeitszeit: (displayFirstOpenArbeitszeit: boolean) => set({ displayFirstOpenArbeitszeit }),
  setDisplayFirstOpenAbwesenheit: (displayFirstOpenAbwesenheit: boolean) => set({ displayFirstOpenAbwesenheit }),
  setDisplayAbwesenheitSelect: (displayAbwesenheitSelect: string) => set({ displayAbwesenheitSelect }),
  setDisplayAbwesenheitSelectDisabled: (displayAbwesenheitSelectDisabled: boolean) =>
    set({ displayAbwesenheitSelectDisabled }),
  setDisplayAbwesenheitSelectMAFA: (displayAbwesenheitSelectMAFA: boolean) => set({ displayAbwesenheitSelectMAFA }),
  setDisplayAbwesenheitSelectSchule: (displayAbwesenheitSelectSchule: boolean) =>
    set({ displayAbwesenheitSelectSchule }),
  setDisplayDetailBisEndlosAbwesenheit: (displayDetailBisEndlosAbwesenheit: boolean) =>
    set({ displayDetailBisEndlosAbwesenheit }),
  setDisplayDetailBisEndlosArbeitszeit: (displayDetailBisEndlosArbeitszeit: boolean) =>
    set({ displayDetailBisEndlosArbeitszeit }),
  setDisplayDetailEditZeitKontext: (displayDetailEditZeitKontext: boolean) => set({ displayDetailEditZeitKontext }),
  setDisplayDetailPause: (displayDetailPause: boolean) => set({ displayDetailPause }),
  setDisplayModalBestaetigung: (displayModalBestaetigung: boolean) => set({ displayModalBestaetigung }),
  setDisplayModalBestaetigungContent: (displayModalBestaetigungContent: typeModalBestaetigung) =>
    set({ displayModalBestaetigungContent }),
  setDisplayModalBestaetigungIsDelete: (displayModalBestaetigungIsDelete: boolean) =>
    set({ displayModalBestaetigungIsDelete }),
  setDisplayModalBestaetigungKopieren: (displayModalBestaetigungKopieren: boolean) =>
    set({ displayModalBestaetigungKopieren }),
  setDisplayModalBestaetigungKopierenText: (displayModalBestaetigungKopierenText: string) =>
    set({ displayModalBestaetigungKopierenText }),
  setDisplayModalBestaetigungValues: (displayModalBestaetigungValues: {}) => set({ displayModalBestaetigungValues }),
  setDisplayModalCopyContent: (displayModalCopyContent: typeModalBestaetigungCopy) => set({ displayModalCopyContent }),
  setDisplayModalError: (displayModalError: boolean) => set({ displayModalError }),
  setDisplayModalLoeschen: (displayModalLoeschen: boolean) => set({ displayModalLoeschen }),
  setDisplayModalErrorContent: (displayModalErrorContent: typeModalBestaetigung) => set({ displayModalErrorContent }),

  setDisplayModalSelectMAFA: (displayModalSelectMAFA: boolean) => set({ displayModalSelectMAFA }),
  setDisplayModalUrlaubsanspruch: (displayModalUrlaubsanspruch: boolean) => set({ displayModalUrlaubsanspruch }),
  setDisplayModalDienstplaeneBeenden: (displayModalDienstplaeneBeenden: boolean) =>
    set({ displayModalDienstplaeneBeenden }),
  setDisplayNewEvent: (displayNewEvent: boolean) => set((state) => ({ ...state, displayNewEvent })),
  setDisplaySettingsModalAbwesenheit: (displaySettingsModalAbwesenheit: boolean) =>
    set({ displaySettingsModalAbwesenheit }),
  setDisplaySettingsModalArbeitszeit: (displaySettingsModalArbeitszeit: boolean) =>
    set({ displaySettingsModalArbeitszeit }),
  setDisplayUrlaubsanspruch: (displayUrlaubsanspruch: number) => set({ displayUrlaubsanspruch }),
  setEndDate: (endDate: string) => set({ endDate }),
  setIsBackDelete: (isBackDelete: boolean) => set({ isBackDelete }),
  setNeuerMAFA: (neuerMAFA: number) => set({ neuerMAFA }),
  setOKButtonColor: (okButtonColor: string) => set({ okButtonColor }),
  setStartDate: (startDate: string) => set({ startDate }),
  setisUrlaubOderKrank: (isUrlaubOderKrank: boolean) => set({ isUrlaubOderKrank }),
  startDate: DEFAULT_DATEPICKER_START_MOMENT.format("YYYY-MM-DD") + "T00:00:00.000Z",
  isFormChanged: false,
  setIsFormChanged: (isFormChanged: boolean) => set({ isFormChanged }),
}));
