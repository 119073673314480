import { Alert, Button, DatePicker, Divider, Form, Input, Modal } from "antd";
import { GENERICTYPE_MODAL_SIZE } from "../../../types/genericTypes";
import { globalMemory } from "../../../globals";
import React, { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/de_DE";
import style from "../Datev/Datev.module.css";
import { RangePickerProps } from "antd/es/date-picker";

interface IWDienstplaeneAbwesenheitenBeendenModalProps {
  displayModal: boolean;
  closeAction: (status: boolean) => void;
  handleOkBPBeenden: (valuesGot: string) => void;
}

const IWDienstplaeneAbwesenheitenBeendenModal: React.FC<IWDienstplaeneAbwesenheitenBeendenModalProps> = ({
  displayModal,
  closeAction,
  handleOkBPBeenden,
}) => {
  const [form] = Form.useForm();

  // ✅  Close Action
  const closeModalAction = useCallback(() => closeAction(false), [closeAction]);

  // ✅  Mindestdatum für DatePicker
  const minSelectableDate = useMemo(() => dayjs(), []);

  // ✅  disableDate Funktion
  const disableDateBeforeValidation: RangePickerProps["disabledDate"] = useCallback(
    (current) => current && current < minSelectableDate,
    [minSelectableDate]
  );

  // ✅  Nachnamen-Berechnung
  const nachname = useMemo(() => {
    const nameParts = globalMemory.ma.split(" ");
    return nameParts[nameParts.length - 1];
  }, [globalMemory.ma]);

  // ✅  Validierungsfunktion
  const validateName = useCallback((name: string) => name === nachname, [nachname]);

  // ✅ Funktion für OK-Button
  const handleOkBPBeendenForm = useCallback(
    (values) => {
      handleOkBPBeenden("" + dayjs(values.beendenAb).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z");
    },
    [handleOkBPBeenden]
  );

  // ✅ Funktion für den ModalTitel
  const generateTitle = () => {
    if (globalMemory.isDPBeenden) {
      return "Aktuellen Dienstplan beenden für  " + globalMemory.ma;
    } else {
      return "Kompletten Dienstplan beenden für  " + globalMemory.ma;
    }
  };

  // ✅ Funktion für den  Text vor dem Datum
  const generateTextDate = () => {
    if (globalMemory.isDPBeenden) {
      return "Bitte wähle das Datum, welches der letzte Tag des aktuellen Dienstplanes ist.";
    } else {
      return "Bitte wähle das Datum, welches der letzte Arbeitstag des Mitarbeiters ist.";
    }
  };

  // ✅ Funktion für für den Text zur Bestätigung
  const generateTextName = () => {
    if (globalMemory.isDPBeenden) {
      return (
        "Bitte Bestätige das Beenden des aktuellen Dienstplans mit der Eingabe des folgenden Teil des Mitarbeiternamens: " +
        nachname +
        "."
      );
    } else {
      return (
        "Bitte Bestätige das Beenden des kompletten Dienstplans mit der Eingabe des folgenden Teil des Mitarbeiternamens: " +
        nachname +
        "."
      );
    }
  };

  return (
    <Modal
      title={generateTitle()}
      width={GENERICTYPE_MODAL_SIZE.GENERICTYPE_MODAL_SIZE_SMALL}
      open={displayModal}
      footer={false}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
    >
      <Divider></Divider>

      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 25 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={handleOkBPBeendenForm}
        autoComplete="off"
      >
        <Form.Item label="" name="sicherheitsCheck" style={{ display: "inline-block", width: "100%" }}>
          <Alert message={generateTextDate()} type="info" />
        </Form.Item>
        <Form.Item
          label=""
          name="beendenAb"
          style={{ display: "inline-block" }}
          initialValue={globalMemory.modalAktDate}
        >
          <DatePicker
            picker={"date"}
            value={globalMemory.modalAktDate}
            allowClear={false}
            format={"DD.MM.YYYY"}
            locale={locale}
            disabledDate={disableDateBeforeValidation}
            inputReadOnly
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            className={style.iwMonthPicker}
          />
        </Form.Item>
        <Form.Item label="" name="sicherheitsCheck" style={{ display: "inline-block", width: "100%" }}>
          <Alert message={generateTextName()} type="info" />
        </Form.Item>
        <Form.Item
          label=""
          name="sicherheitsCheck"
          style={{ display: "inline-block", width: "100%" }}
          hasFeedback
          validateFirst
          rules={[
            {
              message: "Die Namen stimmen nicht überein.",
              validator: (_, value) => {
                if (validateName(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Namen stimmen nicht überein");
                }
              },
            },
          ]}
        >
          <Input placeholder="Bitte bestätige den Mitarbeiternachnamen"></Input>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={closeModalAction}
            style={{
              backgroundColor: "#f5f5f5",
              color: "#4096ff",
              left: 200,
              marginLeft: "5px",
            }}
          >
            Abbrechen
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            style={{
              color: "#f5f5f5",
              left: 200,
            }}
          >
            OK
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IWDienstplaeneAbwesenheitenBeendenModal;
