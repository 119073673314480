import React, { useCallback, useEffect } from "react";
import { convertToLocalDate, disableDateBefore } from "../../../utils/functionsDatum";
import dayjs from "dayjs";
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Switch, TimePicker } from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import style from "../Datev/Datev.module.css";
import { APIEnumTurnusTyp } from "../../../types/apiTypes";
import { globalMemory } from "../../../globals";
import { GENERICTYPE_MODAL_SIZE } from "../../../types/genericTypes";
import { useSollArbeitszeitStore } from "../../../store/zustandSollArbeitszeitenStore";
import { useGenericStore } from "../../../store/zustandGenericStore";
import { tippy } from "@tippyjs/react";

const IWArbeitszeitenmodal = React.memo((props: any) => {
  // ✅ Lade Sollarbeitszeiten spezifische Zustand States
  const {
    displayNewEvent,
    setDisplayDetailEditZeitKontext,
    setDisplayDetailBisEndlosArbeitszeit,
    setDisplayDetailPause,
    displaySettingsModalArbeitszeit,
    displayDetailBisEndlosArbeitszeit,
    displayDetailPause,
    displayDetailEditZeitKontext,
    displayFirstOpenArbeitszeit,
    setDisplayFirstOpenArbeitszeit,
    isFormChanged,
    setIsFormChanged,
  } = useSollArbeitszeitStore();

  // ✅ Lade generelle Zustand States
  const { detailData } = useGenericStore();

  // ✅ Form initialisieren
  let [form] = Form.useForm();

  // ✅ NULL Pausen auf 0:00 setzen
  if (detailData.detailPause === null) detailData.detailPause = "00:00";

  // ✅ Todos für das ersten Load eines Modalöffnen
  if (displayFirstOpenArbeitszeit) {
    form.resetFields();
    if (displayNewEvent) {
      setDisplayDetailPause(false);
    } else {
      setDisplayDetailPause(detailData.detailPausefix);
    }
    setDisplayDetailEditZeitKontext(true);
    setDisplayFirstOpenArbeitszeit(false);
  }

  // ✅ Callback für den OK-Button
  const handleOkArbeitszeiten = useCallback(
    (values) => {
      props.handleOkArbeitszeiten(values, false);
    },
    [props.handleOkArbeitszeiten]
  );

  // ✅ Callback für Cancel-Button
  const handleCancelEinstellungen = useCallback(() => {
    props.handleCancelEinstellungen();
  }, [props.handleCancelEinstellungen]);

  // ✅ Leere Zeit für Disabled Time im Datepicker
  const disabledDateTime = () => {
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  };

  // ✅ Pausen kalkulieren
  const calculatePause = useCallback(
    (value, type) => {
      if (value !== null) {
        // Setze den Wert basierend auf `type`
        if (type.includes("pause1von")) globalMemory.pause1Von = value;
        if (type.includes("pause2von")) globalMemory.pause2Von = value;
        if (type.includes("pause1bis")) globalMemory.pause1Bis = value;
        if (type.includes("pause2bis")) globalMemory.pause2Bis = value;
      }

      // Überprüfen, ob die Pausen-Zeitangaben korrekt sind
      const isValidPause =
        globalMemory.pause1Von.isBefore(globalMemory.pause1Bis) ||
        (globalMemory.pause1Von.isSame(globalMemory.pause1Bis) &&
          globalMemory.pause2Von.isBefore(globalMemory.pause2Bis)) ||
        globalMemory.pause2Von.isSame(globalMemory.pause2Bis);

      if (isValidPause) {
        const calculateDifference = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
          const hours = start.format("HH");
          const minutes = start.format("mm");
          return end.subtract(Number(minutes), "minutes").subtract(Number(hours), "hours");
        };

        const pause1Duration = calculateDifference(globalMemory.pause1Von, globalMemory.pause1Bis);
        const pause2Duration = calculateDifference(globalMemory.pause2Von, globalMemory.pause2Bis);

        const finalHours = pause2Duration.format("HH");
        const finalMinutes = pause2Duration.format("mm");

        const totalPause = pause1Duration.add(Number(finalMinutes), "minutes").add(Number(finalHours), "hours");

        form.setFieldValue("pause", totalPause);
      }
    },
    [form]
  );

  // ✅ Überwache Änderungen im Switch und passe den Turnuswert an
  useEffect(() => {
    if (!displayDetailEditZeitKontext) {
      form.setFieldValue("turnus", APIEnumTurnusTyp.KEINEAUSWAHL); // Setze den Wert auf "-"
    } else {
      form.setFieldValue("turnus", detailData.detailTurnusTyp); // Setze auf Standardwert zurück
    }
  }, [displayDetailEditZeitKontext, form, detailData.detailTurnusTyp]);

  return (
    <Modal
      width={GENERICTYPE_MODAL_SIZE.GENERICTYPE_MODAL_SIZE_LARGE}
      open={displaySettingsModalArbeitszeit}
      okText={"Speichern"}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      footer={null}
    >
      <h3>
        {displayDetailEditZeitKontext ? (
          <b>
            Arbeitszeit für {convertToLocalDate(dayjs(detailData.aktTagDetail).format(" dddd"))},{" "}
            {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" DD.MM.YYYY"))} -{" "}
            {convertToLocalDate(dayjs(detailData.detailDatumBis).format(" DD.MM.YYYY"))}
          </b>
        ) : (
          <b>
            Arbeitszeit für {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" dddd"))},{" "}
            {convertToLocalDate(dayjs(detailData.detailDatumVon).format(" DD.MM.YYYY"))}
          </b>
        )}
        <br />
        {detailData.detailMA}
      </h3>

      <Divider></Divider>
      <Form
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        style={{ maxWidth: 800 }}
        onFinish={handleOkArbeitszeiten}
        form={form}
        autoComplete="off"
        onFieldsChange={function () {
          setIsFormChanged(true);
        }}
      >
        <Form.Item label="Intervall" style={{ marginBottom: "0px" }}>
          <Form.Item name="turnus" style={{ display: "inline-block" }} initialValue={detailData.detailTurnusTyp}>
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              dropdownStyle={{ backgroundColor: "#e1f0fc" }}
              options={[
                { value: APIEnumTurnusTyp.JEDEWOCHE, label: "Jede Woche" },
                { value: APIEnumTurnusTyp.ZWEIWOECHENTLICH, label: "Jede zweite Woche" },
                { value: APIEnumTurnusTyp.KEINEAUSWAHL, label: "Kein Intervall" },
              ]}
            />
          </Form.Item>
          {!displayNewEvent ? (
            <Form.Item
              label="Gültigkeit:"
              name="tagOderTurnus"
              style={{ display: "inline-block", margin: "0 38px" }}
              initialValue={displayDetailEditZeitKontext}
            >
              <Switch
                style={{
                  caretColor: "black",
                  color: "black",
                  width: "150px",
                  backgroundColor: "CornFlowerBlue",
                }}
                defaultChecked={displayDetailEditZeitKontext}
                checkedChildren="Ganze Serie"
                unCheckedChildren="Nur dieser Tag"
                onChange={function (checked: boolean) {
                  setDisplayDetailEditZeitKontext(checked);
                }}
              ></Switch>
            </Form.Item>
          ) : null}
        </Form.Item>

        {displayDetailEditZeitKontext ? (
          <>
            <Form.Item label="Dauer:" style={{ marginBottom: "0px" }}>
              <Form.Item
                name="unbegrenzt"
                style={{ display: "inline-block" }}
                initialValue={displayDetailBisEndlosArbeitszeit}
              >
                <Switch
                  style={{
                    caretColor: "blue",
                    backgroundColor: displayDetailBisEndlosArbeitszeit ? "CornFlowerBlue" : "lightgray",
                  }}
                  defaultChecked={displayDetailBisEndlosArbeitszeit}
                  checkedChildren="Unbegrenzte Dauer"
                  unCheckedChildren="Begrenzte Dauer"
                  onChange={function (checked: boolean) {
                    setDisplayDetailBisEndlosArbeitszeit(checked);
                  }}
                ></Switch>
              </Form.Item>
            </Form.Item>

            <Form.Item label="Gültig ab:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="gueltigAb"
                style={{ display: "inline-block" }}
                initialValue={dayjs(detailData.detailDatumVon)}
              >
                <DatePicker
                  picker={"date"}
                  value={dayjs(detailData.detailDatumVon)}
                  allowClear={false}
                  format={"DD.MM.YYYY"}
                  locale={locale}
                  disabledDate={disableDateBefore}
                  inputReadOnly
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              {!displayDetailBisEndlosArbeitszeit ? (
                <>
                  <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                    <label>bis</label>
                  </Form.Item>
                  <Form.Item
                    label=""
                    name="gueltigBis"
                    style={{ display: "inline-block", margin: "0 8px" }}
                    initialValue={dayjs(detailData.detailDatumBis)}
                  >
                    <DatePicker
                      picker={"date"}
                      allowClear={false}
                      format={"DD.MM.YYYY"}
                      locale={locale}
                      disabledDate={disableDateBefore}
                      inputReadOnly
                      style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                      className={style.iwMonthPicker}
                    />
                  </Form.Item>
                </>
              ) : null}
            </Form.Item>
          </>
        ) : null}
        <Form.Item label="Arbeitszeit:" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="ArbeitszeitAb"
            style={{ display: "inline-block" }}
            initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }

                  const opening_time = getFieldValue("ArbeitszeitBis");

                  //check if opening time is before closing time
                  if (opening_time.diff(value) == 0) {
                    return Promise.reject(new Error("Arbeitszeit von darf nicht gleich bis sein!"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TimePicker
              defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
              allowClear={false}
              format={"HH:mm"}
              locale={locale}
              showNow={false}
              minuteStep={5}
              disabledTime={disabledDateTime}
              changeOnBlur={true}
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            />
          </Form.Item>
          <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
            <label>bis</label>
          </Form.Item>
          <Form.Item
            label=""
            name="ArbeitszeitBis"
            style={{ display: "inline-block", margin: "0 8px" }}
            initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }

                  const opening_time = getFieldValue("ArbeitszeitAb");

                  //check if opening time is before closing time
                  if (opening_time.diff(value) == 0) {
                    return Promise.reject(new Error("Arbeitszeit von darf nicht gleich bis sein!"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <TimePicker
              defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
              allowClear={false}
              format={"HH:mm"}
              locale={locale}
              showNow={false}
              minuteStep={5}
              disabledTime={disabledDateTime}
              changeOnBlur={true}
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              className={style.iwMonthPicker}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Pausentyp" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="PauseVariabel"
            style={{ display: "inline-block" }}
            initialValue={displayDetailPause}
          >
            <Switch
              style={{
                caretColor: "black",
                color: "black",
                width: "150px",

                backgroundColor: "CornFlowerBlue",
              }}
              defaultChecked={displayDetailPause}
              checkedChildren="Fixe Pause"
              unCheckedChildren="Variable Pause"
              onChange={function (checked: boolean) {
                setDisplayDetailPause(checked);
              }}
            ></Switch>
          </Form.Item>
          {/*<Form.Item label="Pause Fix" style={{ display: "inline-block", margin: "0 8px" }}></Form.Item>*/}
        </Form.Item>
        <Form.Item
          label="Pause:"
          name="pause"
          style={{ marginBottom: "15px" }}
          initialValue={dayjs("2000-01-01 " + detailData.detailPause)}
        >
          <TimePicker
            defaultValue={dayjs("2000-01-01 " + detailData.detailPause)}
            allowClear={false}
            format={"HH:mm"}
            disabled={displayDetailPause}
            locale={locale}
            showNow={false}
            onMouseEnter={function (info) {
              tippy(info.currentTarget, {
                content:
                  "<div style={{margin-top: '5px''}}>&nbsp;1. Variable Pause: Gesamtdauer der Pause eintragen&nbsp;<br/>&nbsp;2. Fixe Pause: Zeiträume der Pausen eintragen. <br/>&nbsp;Die Gesamtdauer wird berechnet.</div>",
                placement: "top",
                interactive: true,
                arrow: true,
                inlinePositioning: true,
                theme: "ecut",
                appendTo: document.body,
                allowHTML: true,
                maxWidth: "500px",
                duration: [1, 1],
                offset: [0, 10],
              });
            }}
            minuteStep={5}
            changeOnBlur={true}
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            className={style.iwMonthPicker}
          />
        </Form.Item>
        {displayDetailPause ? (
          <>
            <Form.Item label="Pause 1:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause1Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause1Von)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause1Von)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  showNow={false}
                  onChange={function (value) {
                    calculatePause(value, "pause1von");
                  }}
                  disabledTime={disabledDateTime}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                <label>bis</label>
              </Form.Item>
              <Form.Item
                label=""
                name="pause1Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause1Bis)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause1Bis)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  onChange={function (value) {
                    calculatePause(value, "pause1bis");
                  }}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label="Pause 2:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause2Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause2Von)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause2Von)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  onChange={function (value) {
                    calculatePause(value, "pause2von");
                  }}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item style={{ display: "inline-block", margin: "0 8px" }}>
                <label>bis</label>
              </Form.Item>
              <Form.Item
                label=""
                name="pause2Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause2Bis)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause2Bis)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  onChange={function (value) {
                    calculatePause(value, "pause2bis");
                  }}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </Form.Item>{" "}
          </>
        ) : null}

        <Form.Item
          label="Bemerkung:"
          name="bemerkung"
          style={{ marginBottom: "15px" }}
          initialValue={detailData.detailBemerkung}
        >
          <Input
            defaultValue={"" + detailData.detailBemerkung}
            style={{ backgroundColor: "#e1f0fc", width: "565px" }}
          ></Input>
        </Form.Item>
        <Divider></Divider>

        <Form.Item wrapperCol={{ offset: 0, span: 30 }} style={{ marginBottom: "0px" }}>
          {!displayNewEvent ? (
            <>
              <Button
                type="primary"
                onClick={handleCancelEinstellungen}
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "#4096ff",
                  marginLeft: "450px",
                  marginRight: "5px",
                }}
              >
                Abbrechen
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              onClick={handleCancelEinstellungen}
              style={{
                backgroundColor: "#f5f5f5",
                color: "#4096ff",
                marginLeft: "453px",
                marginRight: "5px",
              }}
            >
              Abbrechen
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default React.memo(IWArbeitszeitenmodal);
