import { Button, Divider, Form, Modal } from "antd";
import { globalMemory } from "../../../globals";
import React from "react";
import { GENERICTYPE_MODAL_SIZE } from "../../../types/genericTypes";
import ReactHtmlParser from "react-html-parser";
import DOMPurify from "dompurify";

const IWConfirmationModal = (props: any) => {
  return (
    <Modal
      title={globalMemory.modalTitle}
      width={GENERICTYPE_MODAL_SIZE.GENERICTYPE_MODAL_SIZE_MEDIUM}
      open={props.displayModal}
      footer={false}
      maskClosable={false}
      okText={globalMemory.confirmationModal.confirmationSubmitText}
      cancelText={globalMemory.confirmationModal.confirmationCancelText}
      closable={false}
      destroyOnClose={true}
    >
      <Divider></Divider>
      {globalMemory.confirmationModal.confirmationText}
      <Divider></Divider>
      <Form
        name="basic"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 450 }}
        initialValues={{ remember: true }}
        onFinish={props.okAction}
        autoComplete="off"
      >
        <Form.Item>
          <Button
            type="primary"
            onClick={props.closeAction}
            style={{
              backgroundColor: "#f5f5f5",
              color: "#4096ff",
              left: 170,
              top: 30,
              marginLeft: "50px",
            }}
          >
            {globalMemory.confirmationModal.confirmationCancelText}
          </Button>{" "}
          <Button
            type="primary"
            htmlType="submit"
            style={{
              position: "absolute",
              left: 323,
              top: 30,
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            {globalMemory.confirmationModal.confirmationSubmitText}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IWConfirmationModal;
